
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthpxWkts3oyuvxhRIqe_OCRdLDCwx8D_nWGZPKAWwMZJAMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93_45HhW6NrASxTe6K_706ItehWwCa8t0LPv01__x11_45v90Meta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as guidesBTRpNryR3gTlqNO4I__45Rz4BjWJN5UXulfSd1dyVdSPMMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as port_45parkingRXJCjVuaFqzGX_Ok5VNTC9Ngc1yoqF8HDxmkrTnmLOcMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as airport_45parkingD_45ri12h0vm60dbXfx2ox2mdSfTpPO6dfksUgWXoUGVMMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as parking_45couponsc9UDJYuREj1DHalxWCyxIVzRCCYzLdhJ_SySdGkiLIMMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as _91result_93UD0tLidGQY47rsE4Z7D1iCVHP3KSNaKjLuknVFIJV6AMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as airport_45shuttlesFqtpoIs0gRYbJ_xbwvWK1swJfEdp6bri7eq_89iChHAMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93CPbZ8r1st_j0K9TIrAN7zxErlm8DdrQ9_45CC6UFGYyA8Meta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as off_45airport_45parkingmF9J0BzqvCXCI_fRuBY4l3sT96upLtzjqhXD3azYUAIMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93_JaUYBZ6091AFgHqW3v2_wYz2_Mg6BCsCWT1If7P6rwMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as port_45hotels_45with_45parkingY9My9YfesgdwO6RwdvOeKWl_gDFc5XWmKLd2_FrODgAMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as airport_45hotels_45with_45parkingFdwJ4x6F5mcvOw0eTIY1bNZxWe4_45cKTZ09ApVrqjaHgMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93_45un_cn1ZgZIbE_eimy69DhTbDUalwpsd_A7B6nRWIQoMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubrWzs4MmJOdWuw9iq_45Ugb34BAyKRNIwm55faCwC7lUUIMeta } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrWzs4MmJOdWuw9iq_45Ugb34BAyKRNIwm55faCwC7lUUI } from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubrWzs4MmJOdWuw9iq_45Ugb34BAyKRNIwm55faCwC7lUUIMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubrWzs4MmJOdWuw9iq_45Ugb34BAyKRNIwm55faCwC7lUUI
  }
]